






import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default Vue.extend({
  name: 'Login',
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    onAuthUIStateChange((nextAuthState, authData) => {
      this.setUser(authData);
      console.log('ON AUTH UI STATE CHANGE', nextAuthState, authData);
    });
  },
  methods: {
    ...mapActions('user', ['setUser']),
    // onHandleChange(authstate: any, data: any) {
    //   console.log('ON HANDLE CHANGE', authstate, data);
    // },
    // onHandleSubmit(event: any) {
    //   console.log('SUBMIT', event);
    // },
  },
});
